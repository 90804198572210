import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { getUserList } from '../utils/API/user';

const UserListContext = createContext({
  users: [],
  usersById: {},
  isLoadingUsers: false,
});

const UserListContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  useEffect(() => {
    setIsLoadingUsers(true);
    getUserList()
      .then(({ results }) =>
        setUsers(
          results.sort((a, b) =>
            (a.full_name || '').localeCompare(b.full_name || '')
          )
        )
      )
      .catch(() => setUsers([]))
      .finally(() => setIsLoadingUsers(false));
  }, []);
  const usersById = useMemo(
    () =>
      Object.freeze(Object.fromEntries(users.map((user) => [user.id, user]))),
    [users]
  );
  return (
    <UserListContext.Provider value={{ users, usersById, isLoadingUsers }}>
      {children}
    </UserListContext.Provider>
  );
};

UserListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserListContext, UserListContextProvider };
