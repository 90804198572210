/* eslint-disable react/jsx-props-no-spreading */

import { FormError, FormField } from '.';

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FormItem = (props) => {
  const { label, name, style } = props;

  return (
    <Form.Group controlId={`form${name}`} className="form-group" style={style}>
      <Form.Label>{label}</Form.Label>
      <FormField {...props} />
      <FormError {...props} />
    </Form.Group>
  );
};

FormItem.defaultProps = {
  name: '',
  label: '',
  disabled: false,
  style: {},
};

FormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

export default FormItem;
