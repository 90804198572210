import PropTypes from 'prop-types';
import { useContext } from 'react';
import { MultiSelectUser } from '../../components/Form/MultiSelectUser';
import { renderSpinner } from '../../utils/Utility';
import { UserListContext } from '../../context/UserListContext';

const minus = (minuendo, ...sottraendi) => {
  const bad = new Set(sottraendi.reduce((a, b) => a.concat(b), []));
  return minuendo.filter((item) => !bad.has(item.id));
};

const Selector = ({
  label,
  availableUsers,
  usersById,
  selected,
  setSelected,
}) => (
  <div className="form-group">
    <label>
      {label}
      <div className="pills">
        {selected.map((id) => (
          <span
            key={+id}
            className="pill removable-pill"
            onClick={() => setSelected(selected.filter((u) => u !== id))}
          >
            {usersById[id].full_name}{' '}
          </span>
        ))}
      </div>
    </label>
    <br />
    <MultiSelectUser
      promptLabel={`Seleziona ${label}`}
      fixedPromptLabel
      emptySelectionLabel="Nessuno"
      users={availableUsers}
      selected={selected}
      setSelected={setSelected}
      variant="light"
      className="multi-select-small"
    />
  </div>
);

Selector.propTypes = {
  label: PropTypes.string.isRequired,
  availableUsers: PropTypes.string.isRequired,
  usersById: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired,
};

export const UserSelector = ({
  participants,
  setParticipants,
  speakers,
  setSpeakers,
  company,
}) => {
  const { users, usersById, isLoadingUsers } = useContext(UserListContext);
  const availableUsers = users.filter(i => i.company === company);
  return (
    <div>
      {renderSpinner(isLoadingUsers)}
      <Selector
        label="Partecipanti"
        availableUsers={minus(availableUsers, speakers)}
        usersById={usersById}
        selected={participants}
        setSelected={setParticipants}
      />
      <Selector
        label="Speakers / organizzatori"
        availableUsers={minus(availableUsers, participants)}
        usersById={usersById}
        selected={speakers}
        setSelected={setSpeakers}
      />
    </div>
  );
};

UserSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  setParticipants: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSpeakers: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
};
