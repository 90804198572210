import PropTypes from 'prop-types';
import 'moment/locale/it';
import '../../css/tempo.scss';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import { UserSelector } from './UserSelector';
import { addTrainingDay, updateTrainingDay } from '../../utils/API/budgets';
import { useContext, useState } from 'react';
import moment from 'moment';
import { UserListContext } from '../../context/UserListContext';
import { success, error } from '../../utils/notification';
import FormDatePicker from '../../components/Form/FormDatePicker';

const initialValues = (currentData) => ({
  id: currentData?.id || undefined,
  date: currentData?.date ? moment(currentData?.date) : moment(),
  description: currentData?.description || '',
  duration: currentData?.duration || 1,
  useDayOff: currentData?.useDayOff || false,
});

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Campo obbligatorio'),
  description: Yup.string()
    .required('Campo obbligatorio')
    .min(5, 'Lunghezza minima 5 caratteri')
    .max(255, 'Lunghezza massima 255 caratteri'),
  duration: Yup.number()
    .required('Campo obbligatorio')
    .positive("Valore non ammesso, dev'essere maggiore di 0"),
});

export const DaysForm = ({ close, currentData, updateData, company }) => {
  const isNew = !currentData?.id;

  const { usersById } = useContext(UserListContext);

  const [participants, setParticipants] = useState(
    currentData?.participants ?? []
  );
  const [speakers, setSpeakers] = useState(currentData?.speakers ?? []);

  const hasMembers = participants.length + speakers.length > 0;

  async function onSubmit(values, { setSubmitting, resetForm, setFieldValue }) {
    const { id, createAnother } = values;
    const p = participants
      .map((userId) => usersById[userId]?.username)
      .filter((u) => u);
    const s = speakers
      .map((userId) => usersById[userId]?.username)
      .filter((u) => u);
    const data = { ...values, date: values.date.format('YYYY-MM-DD') }
    try {      
      if (id) {
        await updateTrainingDay(data, p, s);
      } else {
        await addTrainingDay(data, p, s);
      }
      success('Esito', 'Operazione completata con successo!');          
      updateData();
      if (!createAnother) {
        close();
      } else {
        resetForm();
        setParticipants([]);
        setSpeakers([]);
        setFieldValue('createAnother', createAnother);
      }
    } 
    catch (e) {
      if (e.response.status === 412) {
        const errorData = e.response.data.map(i => `${i.user} (${i.total_spent}/${i.budget})`);
        error('Attenzione!', `Operazione fallita perché i seguenti utenti non hanno disponibilità di giorni: ${errorData.join(' - ')}`);
      } else if (e.response.status === 422) {
        error('Attenzione!', `Il flag Ferie può essere abilitato solo quando viene specificato un unico partecipante.`);
      } else {
        error('Errore!', 'Operazione fallita!');
      }
    }
    finally {
      setSubmitting(false);      
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, updateData);
      }}
      initialValues={initialValues(currentData)}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormDatePicker
            name="date"
            label="Data *"
          />
          <FormItem
            type="textarea"
            name="description"
            rows="3"
            label="Descrizione *"
          />
          <FormItem
            type="number"
            pattern="[0-9]+([\.,][0-9]{1,2})?"
            name="duration"
            label="Durata (giorni) *"
          />
          <FormCheckbox
            name="useDayOff"
            label="Ferie"
          />
          <UserSelector
            participants={participants}
            setParticipants={setParticipants}
            speakers={speakers}
            setSpeakers={setSpeakers}
            company={company}
          />
          {isNew && (
            <>
              <hr />
              <FormCheckbox
                name="createAnother"
                label="Inserisci subito un altro"
              />
            </>
          )}
          <div className="buttonContent">
            <Button variant="secondary" onClick={close}>
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting || !isValid || !hasMembers}
            >
              {currentData?.id ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

DaysForm.propTypes = {
  close: PropTypes.func.isRequired,
  currentData: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    participants: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    speakers: PropTypes.array,
    useDayOff: PropTypes.bool,
  }).isRequired,
  updateData: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
};
