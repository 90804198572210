/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';

import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import { useState } from 'react';
import { saveExpense, updateExpense } from '../../utils/API/expenses';
import DropFileZone from '../../components/Form/DropFileZone';
import { canHaveCompanyInvoice, EXPENSES_TYPES } from '../../constants';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import FormSelect from '../../components/Form/FormSelect';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../../components/Form/FormDatePicker';
import moment from 'moment';

const initialValues = (currentData) => ({
  id: currentData.id || undefined,
  date: currentData.date ? moment(currentData.date) : moment(),
  description: currentData.description || '',
  type: currentData.type || '',
  cost: currentData.cost || '',
  invoice_made_out_to_company: currentData.invoice_made_out_to_company || false,
  is_guild: currentData.is_guild || false,
  attachment: (() => {
    if (!_.get(currentData, 'attachment', null)) {
      return '';
    }
    const fileData = { name: _.get(currentData, 'attachment', null) };
    return { fileData, deleted: true };
  })(),
});

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo obbligatorio'),
  description: Yup.string()
    .required('Campo obbligatorio')
    .min(5, 'Lunghezza minima 5 caratteri')
    .max(50, 'Lunghezza massima 50 caratteri'),
  cost: Yup.number()
    .required('Campo obbligatorio')
    .positive("Valore non ammesso, dev'essere maggiore di 0"),
  date: Yup.date().required('Campo obbligatorio'),
  attachment: Yup.mixed().required('Campo obbligatorio'),
});

export const ExpenseForm = ({ close, currentData, month, updateData }) => {
  const [executeRemoveSelectedFiles, setExecuteRemoveSelectedFiles] =
    useState(false);
  const [isFileAttachment, setIsFileAttachment] = useState(false);
  const attachments = [];

  const isNew = !currentData?.id;

  if (currentData && currentData.attachment) {
    const fileData = { name: _.get(currentData, 'attachment', null) };
    attachments.push({ fileData, deleted: false });
  }
  
  const { t } = useTranslation();

  const createOptions = () => {
    const html = [];
    EXPENSES_TYPES.forEach((item) => {
      const disabled = currentData.id && currentData.type !== 'training_expenses' && item === 'training_expenses';
      html.push(
        <option
          disabled={disabled}
          value={disabled ? '' : item}
          key={item}
        >
          {t(item)}
        </option>
      );
    });
    return html;
  };

  async function onSubmit(values, { setSubmitting, resetForm, setFieldValue }) {
    const {
      id,
      createAnother,
      type,
      invoice_made_out_to_company: companyInvoice,
    } = values;
    const cleanedValues = {
      ...values,
      invoice_made_out_to_company: companyInvoice && canHaveCompanyInvoice(type),
      date: values.date.format('YYYY-MM-DD'),
    };
    try {
      if (id) {
        await updateExpense(cleanedValues);
      } else {
        await saveExpense(cleanedValues);
      }
    } finally {
      setSubmitting(false);
      if (!createAnother) {
        close();
      } else {
        updateData();
        resetForm();
        setExecuteRemoveSelectedFiles(true);
        setFieldValue('createAnother', createAnother);
      }
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions, close, updateData);
      }}
      initialValues={initialValues(currentData)}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
        const enableInvoiceCheckbox = canHaveCompanyInvoice(values.type);
        return (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <FormDatePicker
              name="date"
              label="Data Spesa *"
              minDate={month.clone().startOf('month')}
              maxDate={month.clone().endOf('month')}
            />
            <FormSelect
              name="type"
              label="Tipologia spesa *"
              options={createOptions()}
              disabled={currentData.type === 'training_expenses'}
            />
            <FormItem
              type="textarea"
              name="description"
              rows="3"
              label="Descrizione *"
            />
            <FormItem
              type="number"
              pattern="[0-9]+"
              name="cost"
              label="Spesa (€) *"
            />
            <DropFileZone
              name="attachment"
              fileTypes=".pdf, .png"
              text="Trascina il file oppure fai click per selezionare il file da allegare alla spesa"
              setFieldValue={setFieldValue}
              attachments={attachments}
              executeRemoveSelectedFiles={executeRemoveSelectedFiles}
              setExecuteRemoveSelectedFiles={setExecuteRemoveSelectedFiles}
              setIsFileAttachment={setIsFileAttachment}
            />
            <div className="inline-fields">
              <FormCheckbox
                name="invoice_made_out_to_company"
                label="Fattura intestata all'azienda"
                disabled={!enableInvoiceCheckbox}
              />
              <FormCheckbox
                name="is_guild"
                label="Spesa per gilda"
              />
            </div>
            {isNew && (
              <>
                <hr />
                <FormCheckbox
                  name="createAnother"
                  label="Crea subito un'altra spesa"
                />
              </>
            )}
            <div className="buttonContent">
              <Button variant="secondary" onClick={close}>
                Annulla
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isFileAttachment}
              >
                {currentData.id ? 'Aggiorna' : 'Inserisci'}
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
