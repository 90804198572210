/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { Form } from 'react-bootstrap';
import { ErrorMessage, Field, getIn } from 'formik';
import styled from 'styled-components';
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const FormControl = ({ field, ...props }) => (
  <Form.Control {...field} {...props} />
);
const Error = styled.span`
  color: red;
  padding-bottom: 0;
  font-size: 12px;
  margin-top: 6px;
`;
const FormControlSelect = ({ field, options, ...props }) => (
  <div className="dropdown">
    <Form.Control as="select" {...field} {...props}>
      <option key="" value="">
        Seleziona ...
      </option>
      {options}
    </Form.Control>
  </div>
);

const FormControlTimePicker = ({ field, form, ...props }) => {
  const { label, ...propsForTimePicker } = props;
  return (
    <TimePicker
      {...field}
      {...propsForTimePicker}
      slotProps={{
        textField: {
          helperText: getIn(form.errors, field.name) ?? '',
          error: Boolean(getIn(form.errors, field.name)),
        },
      }}
      onChange={(value) => {
        form.setFieldValue(field.name, moment(value));
      }}
    />
  );
};

const FormControlDatePicker = ({ field, form, ...props }) => {
  const { label, ...propsForDatePicker } = props;
  return (
    <DatePicker
      className='date-picker'
      {...field}
      {...propsForDatePicker}
      slotProps={{
        textField: {
          helperText: getIn(form.errors, field.name) ?? '',
          error: Boolean(getIn(form.errors, field.name)),
        },
      }}
      onChange={(value) => {
        form.setFieldValue(field.name, moment(value));
      }}
    />
  );
};

export const FormField = (props) => (
  <Field component={FormControl} {...props} />
);
export const FormSelect = (props) => (
  <Field component={FormControlSelect} {...props} />
);
export const FormTimePicker = (props) => (
  <Field component={FormControlTimePicker} {...props} />
);
export const FormDatePicker = (props) => (
  <Field component={FormControlDatePicker} {...props} />
);
export const FormError = (props) => (
  <ErrorMessage component={Error} {...props} />
);

export default FormField;
